<template>
  <div class="recharge-page">
    <div class="recharge-container" v-loading="loading">
      <!--
      <p class="item-list" style="color: #f00">
        双11钜惠活动：11月3日—11月20日庆期间，充值即额外赠送10%的积分（添加客户经理领取积分）
      </p>
      -->
      <div class="item-list">
        <div class="left-label">账户ID</div>
        <div class="right-content">
          <div class="right-item userId-box">{{ user.userId }}</div>
          <div class="right-items">
            <span class="gold">余额：{{ user.coinNum }}金币</span>
            <span class="gold pl10" v-if="user.giftCoinNum">(含赠送{{ user.giftCoinNum }}金币)</span>
          </div>
        </div>
      </div>
      <div class="item-list">
        <div class="left-label">
          <div>金币套餐</div>
          <div class="f14 color-9 mt4">(1金币=1元)</div>
        </div>
        <div class="right-content flex-wrap flex-1 ">
          <div class="sd-row">
            <span class="sd-button w200" v-for="(item, index) in dynamicTags" :key="index"
              :class="goldIndex === index ? 'is-active' : ''" @click="tapTag(item, index)">
              <p class="f20">{{ item.coinNum }}金币</p>
              <p class="color-tip">送{{ item.giftCoinNum }}金币</p>
            </span>
          </div>
          <!--
          <template v-for="(item, index) in dynamicTags">
            <div class="right-item" :key="index" v-if="item.name != '自定义'">
              <div
                :class="[
                  'gold-tag',
                  goldIndex == index ? 'gold-tag-active' : '',
                ]"
                @click="tapTag(item, index)"
              >
                @dblclick="dbTapEdit(item, index)"
                <div>{{ item.coinNum }}金币</div>
                <div>送{{ item.giftCoinNum }}金币</div>
              </div>
            </div>
          </template>
          -->
          <!--
          <div class="right-item" v-if="isShowAddTab">
            <el-input
              class="input-new-tag"
              v-if="inputVisible"
              v-model="inputValue"
              ref="saveTagInput"
              size="small"
              @keyup.enter.native="handleInputConfirm"
              @blur="handleInputConfirm"
            >
            </el-input>
            <el-button
              v-else
              class="button-new-tag"
              size="small"
              @click="showInput"
            >
              <span>自定义</span>
              <span class="bottom-tip">1金币=1元</span>
            </el-button>
          </div> -->
        </div>
      </div>
      <div class="item-list">
        <div class="left-label">支付方式</div>
        <div class="right-content">
          <div class="sd-row">
            <span class="sd-button w200" v-for="(el, index) in payMethodList" :key="index"
              :class="payIndex === el.value ? 'is-active' : ''" @click="tapPay(el.value)">
              <div class="flex-center-wrap">
                <i :class="el.icon"></i>
                <span class="pl8 f18 color-2">{{ el.lable }}</span>
              </div>
            </span>
          </div>
        </div>
      </div>
      <div class="item-list flex-align-center">
        <div class="left-label">
          <!-- 实际到账金币 -->
          实际到账
        </div>
        <div class="right-content">
          <span class="coin f18 color-3">
            {{ cur_payPackage.payAmount }}金币+赠送{{ cur_payPackage.giftCoinNum }}金币
            <!-- {{ coin }} -->
          </span>
        </div>
      </div>
      <div class="item-list flex-align-center">
        <div class="left-label">充值金额</div>
        <div class="right-content">
          <span class="gold-num f24 color-tip">￥{{ price }}</span>
        </div>
      </div>
      <!--
      <div class="item-list">
        <div class="left-label">实际到账金币</div>
        <div class="right-content">
          <div class="right-item">
            <span class="coin">{{ coin }}</span>
          </div>
        </div>
      </div>
      <div class="item-list">
        <div class="left-label">本次充值金额</div>
        <div class="right-content">
          <div class="right-item">
            <span class="gold-num">￥{{ price }}</span>
          </div>
        </div>
      </div>
      -->
      <div class="footer-btn">
        <el-button @click="tapToUp">立即充值</el-button>
      </div>
    </div>
    <bind-mobile :bindPhoneDV="bindPhoneDV" @bindPhone="handleBindPhone" />
    <!-- 微信支付 -->
    <el-dialog title="扫码支付" :visible.sync="toUpDV" width="600px" :before-close="closeToUpDv" class="to-up-DV"
      :close-on-click-modal="false">
      <div class="to-up-content">
        <div class="left-code">
          <div class="pay-tip">
            <img src="../../assets/use-account/wx-pay.png" alt="" />
            <span>微信支付</span>
          </div>
          <div class="qrcode" ref="qrCodeUrl" v-if="toUpDV"></div>
          <div class="wx-pay-price">
            <div>
              <span>收款方</span>
              <span>深圳市带塔云科技有限公司</span>
            </div>
            <div>
              <span>商品名称</span>
              <span>{{ shopName }}</span>
            </div>
            <div class="pay-price">
              <span>支付金额</span>
              <span class="price">￥{{ price }}</span>
            </div>
          </div>
        </div>
        <div class="right-tip">
          <img src="../../assets/use-account/wx-pay-tip.png" alt="" />
        </div>
      </div>
    </el-dialog>
    <!-- 支付宝支付 -->
    <el-dialog title="网上支付提示" :visible.sync="zfbDV" width="500px" :before-close="closeZFBDV"
      :close-on-click-modal="false" :show-close="false" class="zfb-DV">
      <template slot="title">
        <div class="zfb-title">网上支付提示</div>
      </template>
      <div class="zfb-content">
        <div class="left-img">
          <i class="el-icon-loading"></i>
        </div>
        <div class="right-tip">
          <div>支付完成前，请不要关闭此支付验证窗口</div>
          <div>支付完成后，请根据您支付的情况点击下面按钮。</div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="pay-issue" @click="closeZFBDV(1)">重新支付</el-button>
        <el-button class="pay-success" @click="$router.push('/recharge-record')">完成支付</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import QRCode from 'qrcodejs2'
import {
  detailList,
  createCoinOrder,
  userInfo,
  paymentStatus,
  queryCoin
} from '@/api/user.js'
// import { queryUserCoin } from '@/api/home.js'
import bindMobile from '@/components/bind-mobile/index.vue'
export default {
  name: 'recharge',
  components: {
    bindMobile
  },
  props: {},
  data() {
    return {
      cur_payPackage: {},
      payMethodList: [
        {
          lable: '微信',
          value: 1,
          icon: 'icon-wchat-pay',
        }, {
          lable: '支付宝',
          value: 0,
          icon: 'icon-zfb',
        }
      ],
      user: {},
      dynamicTags: ['50', '100', '200'],
      inputVisible: false,
      inputValue: '',
      isShowAddTab: false,
      price: '',
      goldIndex: 0,
      payIndex: 1,
      payParams: {
        detailCode: '',
        coinNum: 0,
        payMethod: '1'
      },
      toUpDV: false,
      qrcode: '',
      qrCodeUrl: '',
      loading: false,
      timeId: '',
      orderNo: '',
      editItem: {},
      zfbDV: false,
      coin: 0,
      shopName: '',
      bindPhoneDV: false,
      obj: {
        pageNo: 1,
        pageSize: 30,
        level1CategoryName: '',
        level2CategoryName: '',
        searchData: 'All',
        orderNo: '',
        brandFlag: 'all'
      }
    }
  },
  computed: {},
  watch: {},
  created() {
    this.detailList()
    this.userInfo()
  },
  methods: {
    // 获取套餐信息
    detailList() {
      this.loading = true
      detailList({
        productIds: 1
      }).then(res => {
        this.dynamicTags = res.data
        this.cur_payPackage = { ...this.dynamicTags[0] }
        this.price = this.dynamicTags[0].payAmount
        this.coin = this.dynamicTags[0].amount
        this.shopName = this.dynamicTags[0].name
        this.loading = false
      })
    },
    // 获取用户信息
    userInfo() {
      userInfo().then(res => {
        this.user = res.data || {}
        this.user.coinNum = res.data.coinNum + res.data.giftCoinNum
      })
    },
    // 获取金币余额
    queryCoin() {
      queryCoin().then(res => {
        this.user.coinNum = res.data.coinNum + res.data.giftCoinNum
      })
    },
    // 显示自定义输入框
    // showInput() {
    //   this.inputVisible = true;
    //   this.$nextTick(() => {
    //     this.$refs.saveTagInput.$refs.input.focus();
    //   });
    // },
    // 输入框确认
    // handleInputConfirm() {
    //   let inputValue = this.inputValue;
    //   let reg = /^[0-9]*$/
    //   if (inputValue) {
    //     if (!reg.test(inputValue)) {
    //       this.$message.closeAll()
    //       this.$message.error('请输入数字')
    //       return false
    //     }
    //     if (Object.keys(this.editItem).length > 0) {
    //       this.dynamicTags.push(this.editItem)
    //     }
    //     this.dynamicTags[this.dynamicTags.length - 1].name = inputValue + '金币'
    //     this.payParams.coinNum = inputValue
    //     this.payParams.detailCode = this.dynamicTags[this.dynamicTags.length - 1].detailCode
    //     this.dynamicTags[this.dynamicTags.length - 1].coinNum = inputValue
    //     this.isShowAddTab = false
    //     this.$nextTick(() => {
    //       this.goldIndex = this.dynamicTags.length - 1
    //       this.price = inputValue
    //       this.editItem = {}
    //     });
    //   }
    //   this.inputVisible = false;
    //   this.inputValue = '';
    // },
    // 双击修改
    // dbTapEdit(item, index) {
    //   this.isShowAddTab = true
    //   this.inputVisible = true;
    //   this.editItem = item
    //   this.$nextTick(() => {
    //     this.$refs.saveTagInput.$refs.input.focus();
    //   });
    //   this.inputValue = this.dynamicTags[index].coinNum
    //   this.dynamicTags.splice(index, 1)
    // },
    // 选择充值金币
    tapTag(item, index) {
      // console.log(item)
      this.cur_payPackage = { ...item }
      // payAmount + payAmount = amount
      this.price = item.payAmount
      this.goldIndex = index
      this.coin = item.amount
      this.shopName = item.name
    },
    // 选择支付方式
    tapPay(index) {
      this.payIndex = index
      this.payParams.payMethod = index
    },
    // 充值
    async tapToUp() {
      // queryUserCoin().then(res => {
      //   if (res.success) {
      //     if (res.data.hasBindMobile) {
      //       this.searchFreeNum = res.data.searchFreeNum
      //       this.queryStatus = res.data.coinNum + res.data.giftCoinNum - res.data.searchPrice >= 0 ? true : false
      //       this.queryDV = true
      //       this.searchPrice = res.data.searchPrice
      //     } else {
      //       this.bindPhoneDV = true
      //     }
      //   }
      // })
      let result = await queryCoin()
      if (result.success) {
        if (!result.data.hasBindMobile) {
          this.bindPhoneDV = true
          return false
        }
      }
      if (this.goldIndex !== 3) {
        this.payParams.coinNum = 0
      } else {
        this.payParams.coinNum = this.dynamicTags[this.goldIndex].coinNum
      }
      this.payParams.detailCode = this.dynamicTags[this.goldIndex].detailCode
      createCoinOrder(this.payParams).then(res => {
        if (res.data.type === '1') {
          // 微信
          this.toUpDV = true
          this.qrCodeUrl = res.data.content
          this.creatQrCode()
          this.timeId = setInterval(() => {
            this.paymentStatus(res.data.orderNo)
          }, 3000)
        } else {
          // 支付宝
          let divForm = document.getElementsByTagName('divform')
          if (divForm.length) document.body.removeChild(divForm[0])
          const div = document.createElement('divform')
          div.innerHTML = res.data.content
          document.body.appendChild(div)
          document.forms[0].setAttribute('target', '_blank')
          document.forms[0].submit()
          this.zfbDV = true
        }
      })
    },
    // 查询微信支付
    paymentStatus(orderNo) {
      paymentStatus({
        orderNo
      }).then(res => {
        if (res.success && res.data) {
          this.closeToUpDv()
          this.queryCoin()
          this.$message.success('支付成功')
        }
      })
    },
    // 关闭微信充值弹窗
    closeToUpDv() {
      this.toUpDV = false
      this.timeId && clearInterval(this.timeId)
    },
    // 关闭支付宝弹窗
    closeZFBDV(val) {
      this.zfbDV = false
      if (val === 1) this.queryCoin()
    },
    // 创建支付二维码
    creatQrCode() {
      this.$nextTick(() => {
        this.$refs.qrCodeUrl.innerHTML = ''
        new QRCode(this.$refs.qrCodeUrl, {
          text: this.qrCodeUrl, // 需要转换为二维码的内容
          width: 240,
          height: 240,
          colorDark: '#000000',
          colorLight: '#ffffff',
          correctLevel: QRCode.CorrectLevel.H
        })
      })
    },
    handleBindPhone(val) {
      this.bindPhoneDV = false
      if (val === 1) {
        this.tapToUp()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.recharge-page {
  width: 1008px;
  height: 100%;
  padding: 20px;
  box-shadow: 0px 2px 12px 0px #edecf6;
  border-radius: 4px;

  .recharge-container {
    height: 100%;
    padding: 30px;
    overflow-y: auto;
    border: 1px solid #eceff5;
  }

  .item-list {
    display: flex;
    margin-bottom: 30px;

    .left-label {
      width: 155px;
    }

    .right-content {
      display: flex;

      // align-items: center;
      .right-item {
        position: relative;
        width: 140px;
        /* margin-right: 30px; */
        color: #373e4f;
        font-size: 14px;
        /* margin-top: 3px; */
        margin-bottom: 10px;
        cursor: pointer;

        .gold {
          color: #ffa200;
          font-weight: 700;
          font-size: 20px;
        }

        .gold-num {
          position: absolute;
          bottom: -4px;
          left: -4px;
          font-size: 24px;
          color: #ff455b;
        }

        .coin {
          position: absolute;
          bottom: -10px;
          left: -4px;
          color: #373e4f;
          font-size: 30px;
        }

        .icon {
          display: inline-block;
          width: 20px;
          height: 20px;
          margin-right: 8px;
          margin-top: -5px;
          vertical-align: middle;
        }

        .icon-wx {
          background: url('../../assets/use-account/wx-pay.png') no-repeat;
          background-size: 100% 100%;
        }

        .icon-zfb {
          background: url('../../assets/use-account/zfb-pay.png') no-repeat;
          background-size: 100% 100%;
        }
      }

      .userId-box {
        margin-top: 0;
      }

      .right-items {
        position: relative;
        margin-right: 30px;
        color: #373e4f;
        font-size: 14px;

        .gold {
          display: inline-block;
          color: #ffa200;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: bold;
        }
      }
    }
  }

  .gold-tag {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 90px;
    padding: 0;
    border: 1px solid #a3aab7;
    background-color: #fff;
    font-size: 20px;
    color: #373e4f;
    font-weight: 400;
    text-align: center;
    border-radius: 4px;

    div:nth-child(1) {
      font-size: 20px;
    }

    div:nth-child(2) {
      font-size: 16px;
      color: #ff455b;
    }
  }

  .gold-tag-active {
    width: 120px;
    height: 90px;
    border: 0;
    background: url('../../assets/use-account/active-num .png') no-repeat;
    background-size: 100% 100%;
  }

  .button-new-tag {
    position: relative;
    margin-left: 10px;
    width: 120px;
    height: 68px;
    line-height: 68px;
    padding-top: 0;
    padding-bottom: 0;
    font-size: 20px;
    color: #c8ccde;

    .bottom-tip {
      position: absolute;
      bottom: -22px;
      right: 5px;
      font-size: 12px;
    }
  }

  .input-new-tag {
    width: 120px;
    height: 68px;
    margin-left: 10px;
    vertical-align: bottom;
  }

  .pay-tag {
    width: 126px;
    margin-top: -2px;
    // height: 42px;
    // padding: 0;
    // border: 1px solid #a3aab7;
    // background-color: #fff;
    // font-size: 14px;
    // color: #373e4f;
    // line-height: 36px;
    // text-align: center;
  }

  .pay-tag-active {
    width: 120px;
    height: 36px;
    border: 0;
    background: url('../../assets/use-account/active-pay.png') no-repeat;
    background-size: 100%;
  }

  .footer-btn {
    margin-top: 55px;

    .el-button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 260px;
      height: 42px;
      margin-left: 155px;
      background: linear-gradient(0deg, #0831ff, #3777ff);
      border-color: #3777ff;
      color: #fff;
      font-size: 16px;
    }
  }
}

.to-up-content {
  display: flex;
  justify-content: space-around;

  .left-code {
    width: 240px;

    .pay-tip {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #373e4f;
      font-size: 20px;
      margin-bottom: 30px;

      img {
        width: 27px;
        height: 27px;
        margin-right: 8px;
      }
    }

    .wx-pay-price {
      margin-top: 25px;

      div {
        font-size: 14px;
        margin-bottom: 18px;

        span:nth-child(1) {
          display: inline-block;
          width: 56px;
          color: #a3aab7;
          margin-right: 16px;
        }

        span:nth-child(2) {
          color: #373e4f;
        }
      }

      div:nth-child(2) {
        margin-bottom: 12px;
      }

      .pay-price {
        display: flex;
        align-items: center;

        .price {
          font-size: 30px;
          color: #ff455b;
          margin-left: -5px;
        }
      }
    }
  }

  .right-tip {
    width: 230px;
    height: 415px;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.dialog-footer {
  display: flex;
  justify-content: center;
  margin-top: -15px;

  span {
    cursor: pointer;
    color: #1772f7;
    font-size: 20px;
  }
}

.zfb-DV {
  ::v-deep.el-dialog {
    .el-dialog__header {
      padding: 0;
      overflow: hidden;
    }

    .zfb-title {
      height: 38px;
      line-height: 38px;
      padding-left: 20px;
      color: #fff;
      background: linear-gradient(#3599d6, #1779bf);
    }

    .zfb-content {
      display: flex;
      align-items: center;

      .left-img {
        color: #59aa00;
        font-size: 48px;
      }

      .right-tip {
        margin-left: 28px;
        color: #7b7b7b;
        font-size: 16px;
      }
    }

    .dialog-footer {
      .pay-issue {
        background-color: #edebeb;
        color: #333333;
        margin-right: 22px;
      }

      .pay-success {
        background-color: #ff8000;
        color: #fff;
        border: 1px solid #ff8000;
      }
    }
  }
}
</style>
